/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import moment from "moment";
import { Loading } from "notiflix/build/notiflix-loading-aio";
// @mui material components
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//components
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import Tooltip from "@mui/material/Tooltip";
import { dateTimeFormat } from "utils/constants";
import DetailBlock from "components/DetailBlock";
import EditSchedulerRule from "./Edit";
// rtk queries
import {
  useGetAllConfigsQuery,
  useGetSchedulerStatusQuery,
  useStartSchedulerMutation,
  useStopSchedulerMutation,
} from "services/configs";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Switch } from "@mui/material";
import { useToggleMaintenanceModeMutation } from "services/configs";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

function Configs() {
  const { data: configsList = [], isLoading, } = useGetAllConfigsQuery();
  const [toggleMaintenanceMode] = useToggleMaintenanceModeMutation();
  const { data: schedulerStatus = {}, isLoading: isSchedulerStatusLoading } =
    useGetSchedulerStatusQuery();
  const [startScheduler] = useStartSchedulerMutation();
  const [stopScheduler] = useStopSchedulerMutation();
  const [isMaintenancneMode, setIsMaintenancneMode] = useState(false);

  useEffect(() => {
    isLoading || isSchedulerStatusLoading
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [isLoading, isSchedulerStatusLoading]);

  useEffect(() => {
    if (configsList && configsList[3] && configsList[3].value === "1") {
      setIsMaintenancneMode(true);
    }
  }, [configsList]);

  const handleStartScheduler = async () => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      const result = await startScheduler();
      if (result && result.data?.status == "success") {
        Notify.success("Started the scheduler!");
      } else {
        Notify.failure("Something went wrong. Please try again!");
      }
    } catch (error) {}

    Loading.remove(500);
  };

  const handleStopScheduler = async () => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      const result = await stopScheduler();
      if (result && result.data?.status == "success") {
        Notify.success("Stopped the scheduler!");
      } else {
        Notify.failure("Something went wrong. Please try again!");
      }
    } catch (error) {}

    Loading.remove(500);
  };

  const handleToggleMaintenanceMode = async (_e, checked) => {
    Confirm.show(
      "Please Confirm",
      `Are you sure you want turn the Maitenance mode ${checked ? "On" : "Off"} ?`,
      "Yes",
      "No",
      async () => {
        Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        });
        try {
          const result = await toggleMaintenanceMode(checked);
          if (result && result.data?.status == "success") {
            var isEnable = result.data.response;
            setIsMaintenancneMode(isEnable);
            Notify.success(`Maintenace Mode turned ${isEnable ? "On" : "Off"}`);
          } else {
            Notify.failure("Something went wrong. Please try again!");
          }
        } catch (error) {}

        Loading.remove(500);
      },
      () => {},
      {}
    );
  };

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Configs</MDTypography>}
        action={
          schedulerStatus && schedulerStatus.running ? (
            <Tooltip title="Stop the scheduler" placement="top">
              <IconButton
                aria-label="view"
                size="large"
                onClick={handleStopScheduler}
                sx={{ mr: 1 }}
              >
                <StopCircleIcon color="error" fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Start the scheduler" placement="top">
              <IconButton
                aria-label="view"
                size="large"
                onClick={handleStartScheduler}
                sx={{ mr: 1 }}
              >
                <PlayCircleFilledWhiteIcon color="info" fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )
        }
      />
      <CardContent>
        <br />
        {schedulerStatus && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <DetailBlock
                blockTitle="Scheduler status"
                blockDesc={
                  schedulerStatus.running ? (
                    <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
                  ) : (
                    <MDBadge badgeContent="Inactive" color="error" variant="gradient" size="sm" />
                  )
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <DetailBlock
                blockTitle="Next Trigger time"
                blockDesc={
                  schedulerStatus.running
                    ? moment(schedulerStatus.nextTriggerTime).format(dateTimeFormat)
                    : "N/A"
                }
              />
            </GridItem>
            <br />
          </GridContainer>
        )}
        {configsList && configsList.length > 0 ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <DetailBlock blockTitle="Timezone" blockDesc={configsList[0].value} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <DetailBlock blockTitle="Hour" blockDesc={configsList[1].value} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <DetailBlock blockTitle="Minutes" blockDesc={configsList[2].value} />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <EditSchedulerRule initialData={configsList} />
            </GridItem>
          </GridContainer>
        ) : (
          ""
        )}
        <br />
      </CardContent>
      {configsList && configsList[3] && (
        <CardContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <DetailBlock
                blockTitle="Maintenance Mode"
                blockDesc={
                  configsList[3].value === "1" ? (
                    <MDBadge badgeContent="Enabled" color="success" variant="gradient" size="sm" />
                  ) : (
                    <MDBadge badgeContent="Disabled" color="error" variant="gradient" size="sm" />
                  )
                }
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
              <Switch checked={isMaintenancneMode} onChange={handleToggleMaintenanceMode} />
            </GridItem>
          </GridContainer>
        </CardContent>
      )}
    </Card>
  );
}

export default Configs;
