/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Loading } from "notiflix/build/notiflix-loading-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";

import { Notify } from "notiflix/build/notiflix-notify-aio";

//components
import MDBox from "components/MDBox";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Divider from "@mui/material/Divider";

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Alert from "components/CustomAlert";

import BadgeIcon from '@mui/icons-material/Badge';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import RejectNote from "components/RejectNote";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { globalCountry } from "store/entities/countries";

import { TableRowsTwoTone } from "@mui/icons-material";

import ResellersDetails from "../ResellersDetails";
import OrderHistory from "../OrderHistory";
import DiscountProducts from "../DiscountProducts";

import BDMDialog from "./BDMDialog";
import BDMDirectAssign from "./BDMDirectAssign";

import { useVerifyCompanyMutation } from "services/companies";

import { formatDate } from "utils/helperFunctions";

import DetailBlock from "components/DetailBlock";

function CompanySignupApplication({ details, BDMList }) {
  
  const selectedCountry = useSelector(globalCountry);

  const [openBDMSelect, setOpenBDMSelect] = useState(false);

  const [openBDMAssign, setOpenBDMAssign] = useState(false);

  const [rejectNoteOpen, setRejectNoteOpen] = useState(false);

  const [changeCompanyStatus, { isLoading: isCompanyVerifing }] = useVerifyCompanyMutation();

  const verifyCompany = (id, status) => {
    if(status == "ACTIVE") {
      setOpenBDMSelect(true);
    } else {
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });
  
      try {
        changeCompanyStatus({ company_Id: id, status: status, note: "", assignedBdmId: "" }).then((response) => {
          if (response.data?.status == "success") {
            Notify.success("Successfully verified!");
          }
        });
      } catch (e) {
      } finally {
        Loading.remove(500);
      }
    }
  };

  const onRejectSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      changeCompanyStatus({ company_Id: details.id, status: "REJECTED", note: data.note, assignedBdmId: "" }).then(
        (response) => {
          //   console.log(response);
          if (response.data?.status == "success") {
            Notify.success("Successfully rejected!");
          }
        }
      );
    } catch (e) {
    } finally {
      setRejectNoteOpen(false);
      Loading.remove(500);
    }
  };

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Company Signup Application</MDTypography>}
        action={
          details.status == "PENDING" ? (
            <>
              <MDBox>
                <MDBadge badgeContent="Pending" color="info" variant="gradient" size="sm" />
              </MDBox>
            </>
          ) : details.status == "ACTIVE" ? (
            <MDBox>
              <MDBadge badgeContent="Verified" color="success" variant="gradient" size="sm" />
            </MDBox>
          ) : (
            <MDBox>
              {details.company_note ? (
                <Tooltip title={details.company_note} placement="top">
                  <MDBadge badgeContent="Rejected" color="error" variant="gradient" size="sm" />
                </Tooltip>
              ) : (
                <MDBadge badgeContent="Rejected" color="error" variant="gradient" size="sm" />
              )}
            </MDBox>
          )
        }
      />
      <CardContent>
        {details.status == "PENDING" ? (
          <MDBox sx={{ textAlign: "right", mb: 2 }}>
            <Tooltip title="Verify" placement="top">
              {/* <MDBox sx={{ display: 'inline-block', cursor: details.files.length > 0 ? 'pointer' : 'no-drop' }}> */}
                <MDButton
                  variant="contained"
                  size="small"
                  color="info"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400"
                  }}
                  onClick={() => verifyCompany(details.id, "ACTIVE")}
                  // disabled={details.files.length > 0 ? false : true}
                >
                  Mark as Verified
                </MDButton>
              {/* </MDBox> */}
            </Tooltip>
            <Tooltip title="Reject" placement="top">
              <MDButton
                variant="contained"
                size="small"
                color="error"
                sx={{
                  ml: 1,
                  color: "#fff",
                  fontSize: "10px!important",
                  fontWeight: "400",
                }}
                onClick={() => setRejectNoteOpen(true)}
                // onClick={() => verifyCompany(details.id, "REJECTED")}
              >
                Mark as Rejected
              </MDButton>
            </Tooltip>
            <RejectNote
              dialogTitle="Reject Note"
              dialogNote="A note is required in order to reject a company signup application."
              openNote={rejectNoteOpen}
              closeNote={setRejectNoteOpen}
              onRejectSubmit={onRejectSubmit}
              submitButtonTitle="Reject"
            />
          </MDBox>
        ) : details.status == "ACTIVE" ? (
          <MDBox sx={{ textAlign: "right", mb: 2 }}>
            <Tooltip title="Reject" placement="top">
              <MDButton
                variant="contained"
                size="small"
                color="error"
                sx={{
                  ml: 1,
                  color: "#fff",
                  fontSize: "10px!important",
                  fontWeight: "400",
                }}
                onClick={() => setRejectNoteOpen(true)}
                // onClick={() => verifyCompany(details.id, "REJECTED")}
              >
                Mark as Rejected
              </MDButton>
            </Tooltip>
            <RejectNote
              dialogTitle="Reject Note"
              dialogNote="A note is required in order to reject a company signup application."
              openNote={rejectNoteOpen}
              closeNote={setRejectNoteOpen}
              onRejectSubmit={onRejectSubmit}
              submitButtonTitle="Reject"
            />
          </MDBox>
        ) : (
          <MDBox sx={{ textAlign: "right", mb: 2 }}>
            <Tooltip title="Verify" placement="top">
              <MDButton
                variant="contained"
                size="small"
                color="info"
                sx={{
                  ml: 1,
                  color: "#fff",
                  fontSize: "10px!important",
                  fontWeight: "400",
                }}
                onClick={() => verifyCompany(details.id, "ACTIVE")}
              >
                Mark as Verified
              </MDButton>
            </Tooltip>
            <RejectNote
              dialogTitle="Reject Note"
              dialogNote="A note is required in order to reject a company signup application."
              openNote={rejectNoteOpen}
              closeNote={setRejectNoteOpen}
              onRejectSubmit={onRejectSubmit}
              submitButtonTitle="Reject"
            />
          </MDBox>
        )}
        <GridContainer>
          {details.status == "REJECTED" && details.company_note ? (
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert
                sx={{
                  marginBottom: "15px",
                  alignItems: "center",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  lineHeight: 1,
                  "& .MuiAlert-icon": {
                    fontSize: "18px",
                    opacity: 1,
                    padding: "4px 0",
                    marginRight: "10px",
                    marginBottom: 0
                  },
                }}
                severity="error"
              >
                {details.company_note}
              </Alert>
            </GridItem>
          ) : (
            ""
          )}
          {details.status == "ACTIVE" ? details.assigned_bdms && details.assigned_bdms.length > 0 && details.assigned_bdms[0].bdms ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Assign BDM" icon={<Tooltip title="Reassign BDM" placement="top"><IconButton aria-label="delete" color="info" size="small" sx={{ marginLeft: '10px', marginTop: '-5px', '& .MuiSvgIcon-root': { fontSize: '1.2rem!important' } }} onClick={() => setOpenBDMAssign(true)}><PersonAddIcon fontSize="inherit" /></IconButton></Tooltip>} blockDesc={details.assigned_bdms[0].bdms.first_name + ' ' + details.assigned_bdms[0].bdms.last_name} />
            </GridItem>
          ) : <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <DetailBlock blockTitle="Assign BDM" icon={<Tooltip title="Assign BDM" placement="top"><IconButton aria-label="delete" color="info" size="small" sx={{ marginLeft: '10px', marginTop: '-5px', '& .MuiSvgIcon-root': { fontSize: '1.2rem!important' } }} onClick={() => setOpenBDMAssign(true)}><PersonAddIcon fontSize="inherit" /></IconButton></Tooltip>} blockDesc={"Please assing a BDM!"} />
          </GridItem> : ""}

          {details.name && (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Name" blockDesc={details.name} />
            </GridItem>
          )}

          {details.id && (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Company ID" blockDesc={details.id} />
            </GridItem>
          )}

          {details.email && (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Email" blockDesc={details.email} />
            </GridItem>
          )}

          {details.address ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Company Address" blockDesc={details.address} />
            </GridItem>
          ) : (
            ""
          )}

          {details.postcode ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Company Postcode" blockDesc={details.postcode} />
            </GridItem>
          ) : (
            ""
          )}

          {details.billing_address ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Billing Address" blockDesc={details.billing_address} />
            </GridItem>
          ) : (
            ""
          )}

          {details.biling_postcode ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Billing Postcode" blockDesc={details.biling_postcode} />
            </GridItem>
          ) : (
            ""
          )}

          {details.website ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Website" blockDesc={details.website} />
            </GridItem>
          ) : (
            ""
          )}

          {details.main_phone ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Main Phone" blockDesc={details.main_phone} />
            </GridItem>
          ) : (
            ""
          )}

          {details.type ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Type" blockDesc={details.type} />
            </GridItem>
          ) : (
            ""
          )}

          {details.gst_no ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="GST No" blockDesc={details.gst_no} />
            </GridItem>
          ) : (
            ""
          )}

          {details.reg_no ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Registraion No" blockDesc={details.reg_no} />
            </GridItem>
          ) : (
            ""
          )}

          {details.no_of_employees ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="No of Employees" blockDesc={details.no_of_employees} />
            </GridItem>
          ) : (
            ""
          )}

          {details.years_in_business ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Years in Business" blockDesc={details.years_in_business} />
            </GridItem>
          ) : (
            ""
          )}

          {details.years_in_business ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Years in Business" blockDesc={details.years_in_business} />
            </GridItem>
          ) : (
            ""
          )}

          {details.year_end_date ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Year end Date"
                blockDesc={formatDate(details.year_end_date)}
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.expected_monthly_purchase ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Expected monthly purchase"
                blockDesc={details.expected_monthly_purchase}
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.trading_as ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Trading as" blockDesc={details.trading_as} />
            </GridItem>
          ) : (
            ""
          )}

          {details.proprietor ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Proprietor" blockDesc={details.proprietor} />
            </GridItem>
          ) : (
            ""
          )}

          {details.is_public_company ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Is public company?"
                blockDesc={
                  details.is_public_company ? (
                    <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                  ) : (
                    <MDBadge badgeContent="No" color="warning" variant="gradient" size="sm" />
                  )
                }
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.is_audited_on_year_end ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Is audited on year end?"
                blockDesc={
                  details.is_audited_on_year_end ? (
                    <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                  ) : (
                    <MDBadge badgeContent="No" color="warning" variant="gradient" size="sm" />
                  )
                }
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.is_part_ship ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Is part ship?"
                blockDesc={
                  details.is_part_ship ? (
                    <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                  ) : (
                    <MDBadge badgeContent="No" color="warning" variant="gradient" size="sm" />
                  )
                }
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.enable_free_shipping ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Shipping status"
                blockDesc={
                  details.enable_free_shipping ? (
                    <MDBadge badgeContent="Free shipping" color="info" variant="gradient" size="sm" />
                  ) : (
                    <MDBadge badgeContent="Paid shipping" color="success" variant="gradient" size="sm" />
                  )
                }
              />
            </GridItem>
          ) : (
            ""
          )}

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
          </GridItem>

          {details.account_payable_contact ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Account payable contact"
                blockDesc={details.account_payable_contact}
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.account_payable_phone ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Account payable phone"
                blockDesc={details.account_payable_phone}
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.account_payable_fax ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Account payable fax"
                blockDesc={details.account_payable_fax}
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.account_payable_email ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Account payable email"
                blockDesc={details.account_payable_email}
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.booking_officer ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Booking officer" blockDesc={details.booking_officer} />
            </GridItem>
          ) : (
            ""
          )}

          {details.booking_officer_email ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Booking officer email"
                blockDesc={details.booking_officer_email}
              />
            </GridItem>
          ) : (
            ""
          )}

          {details.booking_officer_phone ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Booking officer phone"
                blockDesc={details.booking_officer_phone}
              />
            </GridItem>
          ) : (
            ""
          )}

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
          </GridItem>

          {details.invoice_email ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Invoice email" blockDesc={details.invoice_email} />
            </GridItem>
          ) : (
            ""
          )}

          {details.invoice_ninja_client_id ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock
                blockTitle="Invoice ninja client ID"
                blockDesc={details.invoice_ninja_client_id}
              />
            </GridItem>
          ) : (
            ""
          )}

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
          </GridItem>

          <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <DetailBlock
              blockTitle="Is credit customer?"
              blockDesc={
                details.is_credit_customer ? (
                  <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                ) : (
                  <MDBadge badgeContent="No" color="warning" variant="gradient" size="sm" />
                )
              }
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
            <DetailBlock
              blockTitle="Is tax exemption?"
              blockDesc={
                details.tax_execemption ? (
                  <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                ) : (
                  <MDBadge badgeContent="No" color="warning" variant="gradient" size="sm" />
                )
              }
            />
          </GridItem>

          {details.is_credit_customer && details.credit_limit ? (
            <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
              <DetailBlock blockTitle="Credit limit" blockDesc={details.credit_limit} />
            </GridItem>
          ) : (
            ""
          )}
        </GridContainer>
        <br />
        <Box mt={2}>
          <ResellersDetails company={details} />
          <OrderHistory company={details} />
          <DiscountProducts company={details} />
          <BDMDialog openBDMSelect={openBDMSelect} setOpenBDMSelect={setOpenBDMSelect} BDMList={BDMList} companyDetails={details} />
          <BDMDirectAssign openBDMAssign={openBDMAssign} setOpenBDMAssign={setOpenBDMAssign} BDMList={BDMList} companyDetails={details} />
        </Box>
      </CardContent>
    </Card>
  );
}

export default CompanySignupApplication;
